import {ApiMethod, DofApi} from "@/managers/net/Api";
import IApi from "@/managers/net/i-api";
import {DOF_API_BASE_URL} from "@/request-apis/dof/dof-api-base";

export class ApiDofPrizeList extends DofApi<any> implements IApi {
    public path = DOF_API_BASE_URL + "/prize/list.php";
    public method = ApiMethod.GET;
    public constructor(query:{aid:string, cid?:string}) {
        super(query);
    }
}

export class ApiDofPrizeGet extends DofApi<any> implements IApi {
    public path = DOF_API_BASE_URL + "/prize/get.php";
    public method = ApiMethod.GET;
    public constructor(query:{cid:string, pid:string, times?:string}) {
        super(query);
    }
}
