import {ApiMethod, DofApi} from "@/managers/net/Api";
import IApi from "@/managers/net/i-api";
import {DOF_API_BASE_URL} from "@/request-apis/dof/dof-api-base";

export class ApiDofActivityList extends DofApi<any> implements IApi {
    public path = DOF_API_BASE_URL + "/activity/list.php";
    public method = ApiMethod.GET;
    public constructor() {
        super();
    }
}

export class ApiDofActivityInfo extends DofApi<any> implements IApi {
    public path = DOF_API_BASE_URL + "/activity/get_info.php";
    public method = ApiMethod.GET;
    public constructor(query:{aid:string}) {
        super(query);
    }
}
