




























































































import {Vue, Component} from 'vue-property-decorator';
import SimpleLayout from "@/views/layouts/SimpleLayout.vue";
import {ApiDofActivityInfo} from "@/request-apis/dof/activity/dof-activity-api";
import UserManager, {ILoginUserInfo, UserEvent} from "@/managers/user-manager";
import {ApiDofPrizeGet, ApiDofPrizeList} from "@/request-apis/dof/prize/dof-prize-api";
import {ApiDofUserGetCharactors} from "@/request-apis/dof/user/dof-user-api";

@Component({
  components: {SimpleLayout}
})
export default class DofActivity extends Vue {
  private code = "loading";
  private msg = "";
  private actId = 0;
  private actType = 0;
  private actTitle = "";
  private actDescription = "";
  private loginUserInfo:ILoginUserInfo | null = null;
  private selectCharId: number | null = null;
  private allCharactors: any[] | null = null;
  private prizeList:any[] | null = null;
  private gettingPrizeId = -1;

  public created() {
    document.title = "DOF活动展示页";
  }

  private mounted() {
    this.init();
  }

  private destroyed() {
    UserManager.getInstance().off(UserEvent.ON_LOGIN_STATE_REFRESH, this, this.onLoginStateRefresh);
  }

  private async init() {
    const actId = this.$route.params.activityId;
    const res = await new ApiDofActivityInfo({aid: actId}).run();
    this.code = res.code;
    this.msg = res.msg;
    this.actId = res.result.id;
    this.actType = res.result.type;
    this.actTitle = res.result.name;
    this.actDescription = res.result.desc;
    document.title = `${this.actTitle} - 米萌DNF`;
    UserManager.getInstance().on(UserEvent.ON_LOGIN_STATE_REFRESH, this, this.onLoginStateRefresh);
    this.onLoginStateRefresh();
  }

  private onLoginStateRefresh() {
    console.log("onLoginStateRefresh");
    this.loginUserInfo = UserManager.getInstance().getLoginUserInfo();
    this.refreshInfos();
  }

  private onLoginClick() {
    if(this.$store.state.device === 'pc') {
      UserManager.getInstance().popupLogin('popup', window.location.href);
    } else if(this.$store.state.device === 'mobile') {
      UserManager.getInstance().popupLogin('jump', window.location.href);
    }
  }

  protected onSelectCharactor() {
    this.refreshPrizeList();
  }

  protected async onGetPrizeClick(prizeId: number) {
    let self:any = this;
    this.gettingPrizeId = prizeId;
    try {
      if(this.loginUserInfo == null) {
        throw { msg: "请先登录" };
      }
      if(this.allCharactors == null) {
        throw { msg: "暂未绑定米萌DNF账号，请先绑定账号。" };
      }
      if(!this.selectCharId) {
        throw { msg: "未选择角色，请先选择角色。" };
      }
      const res = await new ApiDofPrizeGet({cid: this.selectCharId + "", pid: prizeId + ""}).run();
      self.$success({
        title: '领取成功',
        content: '奖励领取成功，请小退后查收邮件。',
        okText: '确定',
      })
      this.refreshPrizeList();
    } catch (e) {
      self.$error({
        title: '领取失败',
        content: e.msg,
        okText: '确定',
      })
    }
    this.gettingPrizeId = -1;
  }

  private async refreshInfos() {
    try {
      await this.refreshCharactors();
    } catch (e) {
      if(e.code == "no_bind_account") {
        let self:any = this;
        self.$confirm({
          title: '提示',
          content: '暂时未绑定米萌DNF账号，点击确定立即跳转去绑定',
          okText: '确定',
          cancelText: '取消',
          onOk: () => {
            this.jumpToBindAccount();
          },
          onCancel() {
            console.log("cancel")
          },
        })
      }
    }
    await this.refreshPrizeList();
  }

  private async refreshCharactors() {
    this.allCharactors = null;
    this.selectCharId = null;
    if(this.loginUserInfo) {
      const res = await new ApiDofUserGetCharactors().run();
      const chars = res.result;
      this.allCharactors = chars;
      this.selectCharId = chars.length > 0 ? chars[0].id : null;
    }
  }

  private async refreshPrizeList() {
    this.prizeList = null;
    try {
      const res = await new ApiDofPrizeList({aid: this.actId + "", cid: this.selectCharId ? this.selectCharId + "" : undefined}).run();
      const result = res.result as any[];
      const list:any[] = [];
      for(const item of result) {
        const lvLimit = item.limit.lv as number;
        const accLimit = item.limit.acc as number;
        const charLimit = item.limit.char as number;
        const accGetted = item.getted?.acc;
        const charGetted = item.getted?.char;

        const limits = [];
        if(lvLimit > 0) limits.push(["lv", lvLimit]);
        if(accLimit > 0) limits.push(["acc", accLimit]);
        if(charLimit > 0) limits.push(["char", charLimit]);
        item.limits = limits;

        item.canGet = true;
        const surplus = [];
        if(accGetted != undefined && accLimit > 0) {
          const sur = accLimit - accGetted;
          surplus.push(["acc", sur]);
          if(item.canGet && sur <= 0) item.canGet = false;
        }
        if(charGetted != undefined && charLimit > 0) {
          const sur = charLimit - charGetted;
          surplus.push(["char", sur]);
          if(item.canGet && sur <= 0) item.canGet = false;
        }
        item.surplus = surplus;

        list.push(item);
      }
      list.sort((a:any, b:any) => {
        if(a.canGet != b.canGet) {
          const aCanGet = a.canGet ? 1 : 0;
          const bCanGet = b.canGet ? 1 : 0;
          return bCanGet - aCanGet;
        } else if(a.weight != b.weight) {
          return b.weight - a.weight;
        } else {
          return a.pid - b.pid;
        }
      });
      this.prizeList = list;
    } catch (e) {

    }

  }

  private jumpToBindAccount() {
    const location = "//keqing.magecorn.com/dof/accounts/bind";
    window.location.href = location;
  }
}
